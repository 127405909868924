import React from 'react';

const CommonChevronDown = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 7.5L10.5 12.5L15.5 7.5"
      stroke="#D0D5DD"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CommonChevronDown;
